import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 1270.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1270.000000) scale(0.100000,-0.100000)">
          
          <path d="M5500 9589 c-105 -5 -152 -13 -212 -33 -42 -14 -90 -26 -106 -26 -16
0 -37 -9 -46 -19 -10 -10 -32 -22 -51 -25 -18 -4 -35 -10 -37 -15 -2 -5 -25
-20 -53 -35 -85 -45 -139 -77 -174 -105 -18 -14 -40 -31 -50 -37 -75 -48 -309
-291 -339 -351 -8 -18 -19 -33 -23 -33 -9 0 -68 -86 -80 -117 -5 -13 -13 -23
-18 -23 -5 0 -24 -26 -42 -57 -38 -67 -54 -95 -89 -154 -14 -23 -37 -63 -52
-88 -15 -25 -36 -65 -47 -87 -10 -23 -24 -45 -29 -48 -6 -3 -16 -28 -23 -55
-8 -31 -19 -51 -29 -54 -12 -3 -20 -20 -24 -51 -5 -31 -12 -46 -22 -46 -17 0
-34 -39 -34 -79 0 -28 -24 -46 -57 -42 -6 1 -15 -4 -19 -10 -4 -7 -3 -9 2 -6
23 14 37 -10 29 -49 -5 -23 -10 -32 -12 -21 -3 9 -9 17 -14 17 -5 0 -9 7 -9
16 0 9 -9 27 -20 41 -15 19 -17 30 -9 44 8 16 6 19 -14 19 -12 0 -29 6 -36 13
-10 10 -15 8 -26 -6 -9 -13 -14 -15 -17 -5 -4 10 -8 11 -20 1 -8 -7 -18 -10
-21 -6 -4 3 -7 -9 -7 -27 0 -18 -3 -30 -7 -27 -3 4 -12 2 -19 -4 -20 -16 -29
9 -10 30 22 24 21 29 -15 63 -23 22 -36 28 -52 23 -12 -4 -30 -7 -39 -7 -9 1
-20 -8 -23 -19 -4 -10 -15 -24 -27 -30 -14 -8 -19 -18 -15 -35 2 -13 0 -21 -5
-18 -4 3 -11 -2 -14 -10 -5 -12 -11 -13 -32 -5 -15 6 -36 13 -47 15 -11 2 -21
7 -22 11 -4 11 -85 9 -102 -1 -9 -6 -12 -5 -7 2 9 16 -35 44 -50 31 -11 -9
-26 9 -28 35 -1 5 -7 11 -13 14 -19 6 -16 -35 2 -42 19 -7 20 -45 1 -52 -17
-7 -25 -35 -32 -117 -6 -75 -20 -187 -35 -282 -16 -103 -18 -1009 -1 -1151 16
-140 18 -520 3 -538 -6 -7 -8 -16 -4 -20 4 -4 -1 -4 -11 0 -11 4 -21 2 -27 -7
-6 -9 -9 8 -9 48 0 35 -5 67 -12 75 -32 34 -38 50 -48 139 -6 52 -15 107 -22
123 l-12 29 -25 -54 c-25 -52 -26 -62 -29 -285 -4 -258 9 -500 29 -535 7 -13
14 -35 15 -49 3 -58 17 -106 37 -128 16 -17 22 -39 25 -87 2 -36 8 -65 13 -65
5 0 6 -7 3 -15 -4 -8 3 -23 14 -33 12 -10 25 -42 32 -82 8 -36 17 -68 22 -71
20 -13 18 -54 -5 -71 -15 -13 -18 -18 -7 -18 9 0 11 -3 5 -8 -6 -4 -16 -18
-22 -32 -9 -19 -12 -21 -12 -7 -1 10 -5 15 -11 12 -19 -11 -10 35 10 55 11 11
16 20 12 20 -5 0 -15 19 -22 43 -7 23 -16 51 -20 62 -5 11 -14 36 -21 56 -6
20 -20 41 -30 48 -18 10 -19 7 -19 -42 0 -40 5 -59 20 -75 17 -18 22 -40 27
-136 5 -96 9 -115 23 -119 14 -3 18 -20 22 -77 3 -40 10 -75 15 -79 16 -10 30
-43 37 -89 3 -24 15 -55 25 -70 11 -15 22 -48 26 -74 3 -27 10 -48 14 -48 11
0 31 -47 31 -74 0 -13 9 -31 19 -40 11 -10 23 -32 27 -50 3 -17 15 -39 26 -49
10 -10 21 -32 25 -48 3 -15 12 -34 19 -40 8 -6 23 -31 34 -55 10 -24 23 -44
28 -44 6 0 13 -11 16 -25 3 -13 15 -29 26 -35 11 -6 20 -17 20 -25 0 -8 13
-28 30 -45 17 -17 30 -37 30 -44 0 -7 11 -18 25 -24 14 -6 25 -18 25 -27 0 -8
7 -15 15 -15 9 0 20 -10 26 -23 6 -13 18 -27 28 -32 10 -6 47 -31 82 -57 34
-27 69 -48 76 -48 7 0 17 -8 22 -19 6 -10 26 -22 45 -25 20 -4 41 -13 49 -20
7 -7 31 -19 52 -27 22 -7 47 -18 55 -25 8 -6 49 -19 90 -29 41 -10 78 -22 81
-27 3 -4 43 -13 90 -19 46 -6 102 -19 124 -27 33 -14 99 -17 380 -20 312 -3
348 -2 440 17 55 11 149 25 209 30 60 6 114 15 120 20 6 5 62 16 125 25 62 9
123 23 134 31 11 8 47 17 80 21 42 5 66 14 84 30 19 18 38 24 69 24 51 0 74 8
74 25 0 17 -23 22 -94 20 -40 -1 -62 -7 -70 -17 -9 -13 -42 -17 -156 -22 -80
-4 -163 -9 -185 -11 -74 -9 -488 12 -520 26 -16 7 -41 14 -55 15 -60 7 -155
26 -165 34 -5 4 -26 10 -45 13 -101 15 -136 29 -124 48 3 5 0 10 -5 10 -6 1
-15 3 -21 4 -5 1 -17 3 -26 4 -9 0 -12 6 -8 13 4 7 3 8 -5 4 -6 -4 -18 0 -27
8 -8 9 -22 16 -30 16 -9 0 -12 5 -8 13 4 6 4 9 -1 5 -13 -12 -35 12 -35 38 0
13 -4 24 -9 24 -5 0 -12 8 -15 17 -8 20 12 35 37 27 9 -3 17 -1 17 5 0 6 13
11 29 11 22 0 31 -6 41 -30 12 -28 14 -29 59 -24 25 3 52 7 59 10 6 2 12 -1
12 -7 0 -6 5 -7 13 -2 6 4 14 8 17 8 3 1 20 9 39 19 28 15 48 17 120 12 98 -8
451 17 495 33 16 7 86 16 156 21 70 6 147 17 171 26 24 8 82 20 129 25 47 6
105 19 129 30 24 11 63 22 85 26 23 3 57 14 77 24 19 9 57 22 85 27 27 6 85
25 129 42 44 18 108 43 142 56 34 12 79 32 100 44 21 11 42 20 47 20 8 0 400
193 446 220 8 4 49 28 90 53 41 24 102 59 135 77 33 18 77 43 98 56 35 22 61
39 155 97 34 22 135 87 206 133 42 27 63 42 167 113 52 35 146 100 209 143
103 70 150 104 215 153 11 9 38 29 60 45 65 47 224 170 320 247 49 40 270 232
326 283 291 268 460 441 604 619 98 122 174 273 215 431 17 63 12 205 -9 265
-10 29 -22 72 -26 94 -4 23 -11 43 -16 47 -5 3 -20 26 -33 52 -14 26 -43 67
-65 92 -23 24 -41 48 -41 52 0 4 -7 8 -15 8 -8 0 -19 10 -26 21 -6 12 -27 35
-47 53 -20 17 -55 49 -77 71 -49 48 -97 89 -149 130 -34 26 -74 59 -126 102
-8 7 -28 24 -45 39 -16 14 -82 65 -145 112 -63 48 -117 89 -120 92 -3 3 -32
25 -65 48 -33 23 -69 49 -80 57 -92 71 -314 225 -376 261 -10 6 -27 18 -36 26
-10 8 -76 51 -148 94 -169 103 -156 95 -206 129 -24 16 -61 38 -81 50 -21 11
-97 54 -169 95 -172 98 -202 114 -344 190 -194 103 -409 210 -421 210 -6 0
-17 6 -23 14 -7 8 -24 17 -39 21 -15 4 -67 26 -117 50 -133 63 -197 92 -225
100 -14 4 -32 13 -40 20 -8 7 -35 18 -59 25 -24 6 -60 20 -80 31 -20 10 -51
22 -71 26 -19 3 -48 14 -65 25 -16 10 -42 18 -58 18 -15 0 -46 9 -70 19 -23
11 -66 23 -96 26 -30 4 -78 16 -107 25 -32 11 -99 21 -171 25 -65 3 -131 7
-148 9 -16 1 -91 -1 -165 -5z m-1768 -1651 c-15 -15 -26 -4 -18 18 5 13 9 15
18 6 9 -9 9 -15 0 -24z m753 -442 c3 -11 6 -61 8 -111 3 -110 8 -112 101 -34
53 44 190 129 216 135 14 3 49 14 78 25 77 27 234 27 280 -1 18 -11 42 -20 54
-20 24 0 158 -131 158 -155 0 -8 13 -30 30 -48 l30 -34 55 53 c31 30 72 65 93
79 20 14 48 32 62 41 14 9 37 25 52 35 15 10 44 23 65 28 21 5 56 16 78 25 61
23 229 21 280 -4 22 -11 47 -19 56 -20 24 0 117 -78 135 -112 8 -16 26 -34 39
-40 20 -9 29 -7 54 15 19 16 50 28 79 32 26 4 56 13 67 20 11 6 45 20 75 30
30 10 64 24 75 31 11 7 38 16 60 20 22 4 55 15 74 25 62 33 82 -6 72 -140 -1
-19 0 -45 3 -57 6 -21 9 -22 33 -13 15 5 44 25 65 44 21 19 42 35 46 35 5 0
19 9 32 20 32 27 145 90 163 90 8 0 34 9 58 20 63 28 228 28 284 0 22 -11 45
-20 51 -20 20 0 144 -114 144 -133 0 -4 13 -25 29 -48 17 -22 31 -45 33 -51 2
-6 25 9 51 33 77 73 160 139 175 139 5 0 26 11 46 25 20 14 49 28 64 32 15 3
45 14 67 24 58 27 241 27 299 0 23 -11 56 -25 74 -31 45 -17 140 -125 153
-174 6 -22 16 -49 24 -60 23 -38 35 -275 35 -700 0 -493 2 -506 79 -555 25
-16 65 -31 89 -34 56 -7 75 -22 59 -48 -11 -18 -28 -19 -370 -19 -355 0 -397
4 -397 34 0 15 42 36 74 36 29 0 86 19 86 29 0 4 11 13 24 19 45 20 47 55 44
616 l-3 522 -34 56 c-28 46 -46 63 -95 89 -54 29 -70 33 -138 33 -51 0 -84 -5
-100 -16 -12 -8 -41 -21 -63 -28 -39 -12 -121 -74 -178 -134 l-27 -29 0 -503
c0 -316 4 -511 10 -526 34 -78 88 -123 159 -133 64 -9 76 -17 62 -44 -12 -21
-14 -21 -385 -21 -318 0 -375 2 -386 15 -21 25 -4 41 53 53 71 14 125 52 151
105 20 41 21 56 21 527 l0 485 -26 72 c-15 40 -34 79 -43 88 -9 9 -16 19 -16
23 0 4 -25 20 -55 36 -48 25 -65 28 -132 27 -45 -1 -86 -7 -98 -15 -11 -7 -35
-17 -54 -21 -18 -4 -61 -28 -94 -54 -85 -66 -93 -74 -105 -97 -14 -27 -17
-1023 -3 -1078 15 -63 47 -87 136 -102 43 -7 80 -16 83 -19 3 -3 -3 -14 -13
-25 -18 -20 -30 -20 -627 -20 -401 0 -616 4 -629 10 -43 23 -6 60 60 60 15 0
48 14 73 30 79 53 78 43 78 583 0 259 -4 498 -9 529 -15 88 -64 158 -137 193
-51 25 -70 29 -136 28 -49 -1 -88 -7 -105 -17 -16 -8 -44 -22 -63 -29 -19 -8
-69 -44 -111 -80 -63 -54 -77 -72 -81 -100 -7 -64 -1 -962 7 -1001 5 -26 23
-54 54 -86 40 -42 52 -48 100 -53 65 -8 85 -21 70 -48 -9 -18 -25 -19 -386
-17 -343 3 -377 5 -387 20 -15 25 3 39 62 49 27 4 66 19 88 34 33 22 42 37 59
93 19 63 20 94 19 492 0 394 -2 432 -21 522 -25 115 -54 158 -128 192 -69 33
-92 36 -155 26 -73 -11 -146 -39 -202 -76 -55 -36 -108 -80 -108 -89 0 -4 -9
-18 -20 -32 -19 -25 -20 -41 -20 -504 0 -463 1 -481 22 -544 27 -85 57 -111
138 -120 65 -7 85 -25 59 -50 -11 -12 -81 -15 -372 -15 -329 -1 -360 0 -378
16 -28 25 -7 43 60 51 36 4 63 15 89 35 70 56 67 28 70 603 2 286 0 543 -3
570 -4 37 -14 59 -36 83 -28 30 -34 32 -99 32 -80 0 -100 6 -100 29 0 29 42
58 115 79 90 27 179 58 231 83 22 10 47 19 56 19 9 0 35 9 58 19 49 22 68 18
75 -13z m-358 -1886 c17 -52 37 -114 43 -137 5 -24 16 -43 23 -43 7 0 26 48
45 113 48 161 48 162 102 162 l45 0 3 -60 c2 -33 1 -115 -3 -182 -6 -110 -9
-123 -25 -123 -28 0 -32 12 -39 137 l-6 116 -50 -124 c-33 -83 -56 -125 -67
-127 -19 -4 -46 45 -93 165 -15 40 -32 70 -36 67 -5 -3 -9 -57 -9 -120 0 -113
0 -114 -24 -114 -21 0 -24 5 -30 61 -8 83 -8 294 1 303 4 4 25 6 48 4 l40 -3
32 -95z m473 -39 c25 -74 50 -136 56 -138 5 -2 27 55 48 127 21 71 44 135 52
141 7 5 30 9 51 7 l38 -3 3 -90 c2 -49 0 -132 -3 -182 -6 -81 -9 -93 -25 -93
-25 0 -32 26 -39 149 l-6 104 -42 -109 c-48 -125 -64 -150 -85 -141 -9 3 -38
58 -64 122 l-49 117 -9 -122 c-8 -116 -9 -121 -30 -118 -17 2 -22 12 -28 53
-9 69 -10 300 -1 309 4 4 25 6 48 4 l40 -3 45 -134z m460 128 c51 -12 96 -49
116 -96 20 -48 18 -130 -6 -177 -32 -64 -62 -80 -156 -84 -71 -4 -82 -2 -89
15 -9 23 -20 316 -12 337 8 19 77 21 147 5z m389 -31 c23 -55 88 -253 96 -295
6 -28 4 -33 -12 -33 -10 1 -27 15 -38 33 -19 31 -23 32 -93 35 l-72 3 -17 -36
c-10 -21 -24 -35 -35 -35 -16 0 -17 6 -12 43 9 58 94 310 108 319 30 20 57 8
75 -34z m406 7 c0 -29 -2 -30 -50 -33 -27 -2 -53 -9 -57 -15 -4 -7 -8 -64 -8
-127 0 -131 -6 -162 -28 -158 -22 4 -31 46 -38 181 l-7 117 -54 0 c-52 0 -53
1 -53 28 0 16 3 32 7 36 4 3 70 5 147 4 l141 -3 0 -30z m202 23 c13 -17 113
-318 113 -341 0 -29 -28 -19 -52 18 -23 34 -24 35 -95 35 l-73 0 -14 -35 c-20
-47 -46 -47 -46 0 0 33 39 167 82 283 17 46 23 52 47 52 16 0 33 -6 38 -12z
m387 -3 c30 -14 66 -60 66 -84 0 -22 -62 -12 -86 14 -28 30 -74 33 -98 6 -30
-33 -12 -56 70 -91 86 -38 104 -58 104 -116 0 -61 -32 -84 -117 -84 -69 0
-116 17 -133 50 -26 49 29 55 58 6 36 -61 124 -53 130 12 4 44 -7 55 -80 82
-106 39 -146 105 -102 169 34 48 126 65 188 36z m196 -141 c4 -131 8 -155 24
-173 21 -24 84 -29 104 -9 8 8 12 61 12 173 l0 161 30 2 c19 2 32 -4 38 -15 5
-10 6 -80 3 -156 l-6 -139 -33 -29 c-28 -25 -40 -29 -93 -29 -123 0 -149 42
-149 239 l0 128 33 -1 32 -1 5 -151z m489 132 c55 -29 68 -92 30 -140 l-21
-27 21 -23 c31 -33 29 -95 -5 -127 -23 -21 -36 -24 -119 -27 -107 -4 -98 -12
-115 103 -11 82 -13 248 -2 259 12 14 178 -1 211 -18z m485 10 c37 -16 76 -64
76 -95 0 -31 -61 -30 -75 1 -16 37 -57 54 -92 39 -41 -17 -53 -48 -53 -139 0
-101 24 -142 83 -142 38 0 70 18 63 36 -3 8 -2 12 3 9 5 -3 13 4 16 15 5 14
14 20 28 18 31 -5 31 -23 -1 -59 -27 -31 -35 -34 -101 -37 -70 -4 -71 -4 -103
31 -54 57 -57 64 -64 119 -10 90 30 184 90 208 32 13 93 11 130 -4z m374 -13
c88 -64 97 -241 16 -312 -30 -27 -42 -31 -92 -31 -68 0 -117 25 -136 70 -7 17
-15 28 -18 25 -10 -11 -19 84 -12 130 14 94 80 149 167 142 27 -2 61 -13 75
-24z m218 19 c6 -4 31 -67 55 -139 40 -123 59 -159 59 -115 0 15 2 15 11 2 8
-12 9 -11 5 5 -3 12 11 72 32 135 l39 115 49 0 49 0 0 -180 c0 -178 0 -180
-22 -183 -26 -4 -30 13 -41 158 l-7 95 -44 -115 c-24 -63 -48 -121 -54 -127
-19 -25 -35 -13 -58 42 -12 30 -25 52 -29 50 -4 -2 -17 24 -29 58 -35 99 -43
103 -36 14 9 -108 -1 -177 -26 -177 -10 0 -19 6 -19 13 -1 6 -7 48 -15 92 -14
75 -21 246 -10 256 8 8 79 9 91 1z m-881 -343 c0 -7 -8 -15 -17 -17 -18 -3
-25 18 -11 32 10 10 28 1 28 -15z m-4235 -726 c0 -22 -2 -25 -10 -13 -13 20
-13 40 0 40 6 0 10 -12 10 -27z m87 5 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
4 -12 1 -19z m1564 -887 c-32 -24 -103 -23 -107 2 -2 15 7 17 64 17 l67 0 -24
-19z m-401 -79 c0 -5 -7 -9 -15 -9 -8 0 -12 4 -9 9 3 4 9 8 15 8 5 0 9 -4 9
-8z m215 -92 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z"/>
<path d="M6384 7267 c-3 -8 1 -27 8 -43 9 -21 14 -171 18 -579 5 -549 5 -550
28 -593 14 -26 36 -49 56 -59 32 -15 35 -14 64 7 23 17 34 36 41 72 14 62 14
1066 1 1123 -15 65 -47 85 -136 85 -53 0 -76 -4 -80 -13z"/>
<path d="M4978 5518 c-2 -68 1 -131 5 -140 18 -40 108 -9 126 43 15 44 14 123
-3 164 -17 40 -42 55 -90 55 l-35 0 -3 -122z"/>
<path d="M5372 5531 c-11 -33 -18 -62 -14 -65 11 -12 92 -6 92 7 0 21 -37 111
-48 115 -5 1 -19 -24 -30 -57z"/>
<path d="M6015 5585 c-36 -92 -43 -114 -38 -119 4 -3 26 -6 51 -6 44 0 44 0
38 31 -3 16 -14 49 -25 72 -16 37 -20 39 -26 22z"/>
<path d="M6990 5590 l0 -50 40 0 c27 0 47 7 60 20 24 24 24 29 4 58 -12 17
-25 22 -60 22 l-44 0 0 -50z"/>
<path d="M6987 5483 c-12 -11 -8 -98 5 -111 16 -16 79 -15 104 2 25 18 31 78
9 100 -17 16 -105 23 -118 9z"/>
<path d="M7854 5627 c-23 -20 -28 -34 -32 -85 -8 -117 23 -182 88 -182 32 0
80 37 80 61 0 7 -7 4 -16 -7 -15 -19 -15 -19 -6 1 31 68 20 176 -23 212 -33
29 -57 29 -91 0z"/>
<path d="M9038 8243 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3131 8134 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3130 8030 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"/>
<path d="M8338 4403 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8235 4360 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M8137 4315 c-5 -10 0 -15 14 -15 12 0 19 5 17 12 -6 18 -25 20 -31 3z"/>
<path d="M7905 4210 c-8 -13 25 -13 45 0 12 8 9 10 -12 10 -15 0 -30 -4 -33
-10z"/>
<path d="M7540 4195 c0 -2 9 -9 20 -15 16 -9 18 -8 14 5 -5 13 -34 22 -34 10z"/>
<path d="M7810 4169 c-21 -5 -22 -7 -8 -13 23 -10 61 -4 65 11 5 13 -16 13
-57 2z"/>
<path d="M7439 4149 c-21 -21 -44 -27 -139 -36 -36 -3 -72 -12 -81 -19 -8 -8
-40 -18 -69 -24 -30 -6 -64 -17 -77 -25 -12 -8 -51 -19 -85 -24 -35 -5 -92
-18 -127 -30 -35 -12 -75 -21 -89 -21 -14 0 -40 -9 -58 -21 -22 -13 -58 -22
-106 -26 -40 -3 -91 -13 -113 -23 -22 -10 -62 -22 -90 -25 -27 -4 -68 -13 -91
-20 -22 -8 -78 -19 -125 -25 -46 -6 -111 -19 -144 -30 -33 -10 -92 -21 -130
-25 -39 -4 -78 -12 -88 -17 -10 -5 -78 -14 -150 -20 -92 -7 -140 -15 -157 -26
-28 -19 -102 -41 -140 -42 -20 0 -22 -2 -9 -10 38 -22 121 -30 336 -31 227 -1
247 1 333 26 25 8 96 16 158 19 129 6 162 13 179 35 9 13 32 16 108 17 89 0
99 2 115 23 15 21 27 24 88 25 55 2 78 7 99 23 16 11 50 23 77 27 26 4 65 17
85 29 27 15 54 21 106 21 64 1 70 3 73 23 3 22 14 28 66 37 16 3 39 15 52 27
26 24 53 20 61 -11 3 -11 12 -20 19 -20 8 0 14 -4 14 -10 0 -5 7 -10 15 -10 8
0 15 8 15 18 0 10 5 23 11 29 13 13 4 33 -16 33 -22 0 -25 28 -4 54 21 27 54
31 80 9 9 -8 28 -15 42 -17 14 -1 32 -7 39 -13 22 -17 44 -22 59 -13 10 7 6
12 -16 22 -56 25 -40 58 27 58 28 0 40 -5 48 -21 13 -22 45 -22 45 1 0 8 -9
16 -20 18 -29 6 -36 15 -37 49 -1 28 -4 30 -37 29 -35 -1 -77 -29 -67 -44 9
-15 -10 -30 -46 -37 -30 -6 -36 -4 -34 9 1 8 14 25 30 37 15 12 24 24 21 27
-13 13 -38 9 -56 -9z"/>
<path d="M7750 4153 c-29 -5 -43 -17 -46 -38 -2 -13 6 -15 34 -13 33 3 37 6
38 31 1 15 1 26 0 26 -1 -1 -12 -4 -26 -6z"/>
<path d="M7501 4124 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7450 3970 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z"/>
<path d="M7175 3873 c-16 -3 -34 -9 -40 -15 -7 -7 5 -9 39 -4 27 3 59 6 72 6
13 0 24 5 24 10 0 10 -37 11 -95 3z"/>
<path d="M7082 3835 l-22 -25 28 1 c15 0 21 3 15 6 -18 7 -16 23 2 23 8 0 15
5 15 10 0 17 -15 11 -38 -15z"/>
<path d="M6652 3721 c-11 -7 -10 -10 3 -15 20 -7 38 1 30 15 -7 11 -16 11 -33
0z"/>
<path d="M6730 3670 c-22 -7 -21 -8 13 -9 23 -1 36 3 32 9 -7 11 -10 11 -45 0z"/>
<path d="M6550 3625 c-11 -13 -6 -15 34 -15 48 0 62 13 24 24 -35 9 -44 7 -58
-9z"/>
<path d="M4695 3570 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6453 3573 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M6172 3533 c-6 -2 -10 -9 -7 -14 3 -4 -10 -15 -30 -23 -22 -10 -34
-20 -30 -27 11 -16 77 -1 93 22 8 11 25 19 42 19 40 0 46 16 9 24 -36 7 -58 7
-77 -1z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
